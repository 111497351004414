import React, { useEffect } from 'react'
import { useUser } from "../components/Layout";
import DashboardLayout from "../components/DashboardLayout";
import { Helmet } from "react-helmet";

export const backendHost = typeof window !== 'undefined' && window.location.hostname === "localhost" ? "http://127.0.0.1:8001" : "https://api.itadise.com";

const DashboardComponent = () => {
  const { email } = useUser("/");

  useEffect(() => {
    if (email) {
      const didToken = localStorage.getItem("didToken");
      console.log(`make request with token ${didToken}`);
      fetch(`${backendHost}/private`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + didToken,
        }
      }).then(res => console.log(`res`, res));
    }
  }, [email]);
  return (
    <div className="mt-36" id="user-info">
      {`Welcome, ${email}`}
    </div>
  );
};

export default function Dashboard ({ data }) {
  return (
    <DashboardLayout logo={data.contentfulBranding.logo.file.url}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard | Itadise</title>
      </Helmet>
      <DashboardComponent />
    </DashboardLayout>
  );
}

export const query = graphql`
  query {
    contentfulBranding {
      logo {
        file {
          url
        }
        title
      }
    }
  }
`

