import React from 'react'
import Layout from "../components/Layout";
import { EnterEmail } from "../components/EnterEmail";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

export default function Insights ({ data }) {

  return (
    <Layout logo={data.contentfulBranding.logo.file.url} selected="insights">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Insights | Itadise</title>
      </Helmet>
      <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              {data.contentfulInsightsOverview.title}
            </h1>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              {data.contentfulInsightsOverview.subtitle.subtitle}
            </p>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {data.allContentfulBlogPost.edges.map(obj => (
              <div key={obj.node.slug} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <img className="h-48 w-full object-cover" src={obj.node.coverImage.file.url} alt="" />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <p className="text-sm font-medium text-indigo-600">
                      <Link to={`/insights/${obj.node.slug}`} className="hover:underline">
                        {obj.node.category || "Article"}
                      </Link>
                    </p>
                    <Link to={`/insights/${obj.node.slug}`} className="block mt-2">
                      <p className="text-xl font-semibold text-gray-900">
                        {obj.node.title}
                      </p>
                      <p className="mt-3 text-base text-gray-500">
                        {obj.node.previewText.previewText}
                      </p>
                    </Link>
                  </div>
                  {obj.node.author && (
                    <div className="mt-6 flex items-center">
                      <div className="flex-shrink-0">
                        <span className="sr-only">{obj.node.author.name}</span>
                        {obj.node.author.picture && <img className="h-10 w-10 rounded-full" src={obj.node.author.picture.file.url} alt="" />}
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">
                          {obj.node.author.name}
                        </p>
                        <div className="flex space-x-1 text-sm text-gray-500">
                          <time dateTime="2020-03-16">
                            {obj.node.createdAt}
                          </time>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <EnterEmail />
    </Layout>
  )
};

export const query = graphql`
  query {
    contentfulBranding {
      logo {
        file {
          url
        }
        title
      }
    }
    contentfulInsightsOverview {
      title
      subtitle {
        subtitle
      }
    }
    allContentfulBlogPost {
      edges {
        node {
          slug
          title
          category
          previewText {
            previewText
          }
          createdAt(formatString: "DD MMMM, YYYY")
          coverImage {
            file {
              fileName
              url
            }
          }
          author {
            name
            picture {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`;